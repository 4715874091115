import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
import Principal from '../views/Principal.vue';
import Home from '../views/home/Index.vue';
import Login from '../views/segurity/Login.vue';

import viewProductoDetalle from '../views/catalogos/productos/ViewProductoDetalle.vue';
import ListadoProductos from '../views/catalogos/productos/ListadoProductos.vue';
import viewWearWall from "../views/catalogos/wearwall/viewWearWall.vue";
import Bolsa from "../views/catalogos/carrito/Bolsa.vue";
import ModoEnvio from "../views/catalogos/carrito/ModoEnvio.vue";
import MetodoPago from "../views/catalogos/carrito/MetodoPago.vue";

import prueba from '../views/catalogos/productos/carrousel.vue';
import ViewCliente from '@/views/cliente/viewCliente.vue';
import ViewPedidoDetalle from "@/views/cliente/viewDetallePedido.vue";
import ActivarCuenta from '@/views/segurity/ActivarCuenta.vue';
import RecuperaCuenta from '@/views/segurity/RecuperaCuenta.vue';
import ResultadoPago from '@/views/catalogos/carrito/ResultadoPago.vue';

import Nosotros from '@/views/catalogos/informacion/Nosotros.vue';
import Politicas from '@/views/catalogos/informacion/Politicas.vue';
import Terminos from '@/views/catalogos/informacion/Terminos.vue';
import Garantias from '@/views/catalogos/informacion/Garantias.vue';
import Contacto from '@/views/catalogos/informacion/Contacto.vue';

import ListadoEventos from '@/views/catalogos/eventos/ListadoEventos.vue';
import ViewEventoDetalle from '@/views/catalogos/eventos/viewEventoDetalle.vue';
import CompraTickets from '@/views/catalogos/eventos/ViewTickets.vue';
import PagoTickets from '@/views/catalogos/eventos/PagoTicketEvento.vue';
import ResultadoPagoEvento from '@/views/catalogos/eventos/ResultadoPagoEvento.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        props: true,
    },
    {
        path: '/recoveryaccount/:key/:id/:num',
        name: 'recoveryaccount',
        component: RecuperaCuenta,
    },
    {
        path: '/confirmaccount',
        name: 'confirmaccount',
        component: ActivarCuenta,
    },

    {
        path: '/prueba',
        name: 'prueba',
        component: prueba,
        props: false,

    },

    {
        path: '/',
        name: 'principal',
        component: Principal,
        props:true,
        children:[
            {
                path: '/home',
                name: 'home',
                component: Home
            },
            {
                path: '/viewproducto',
                name: 'viewproducto',
                component: viewProductoDetalle,
                props: false,

            },
            {
                path: '/productos',
                name: 'productos',
                component: ListadoProductos,
                props: true,

            },
            {
                path: '/wearwall',
                name: 'wearwall',
                component: viewWearWall,
                props: true,

            },
            {
                path: '/bolsa',
                name: 'bolsa',
                component: Bolsa,
                props: true,

            },
            {
                path: '/eventos',
                name: 'eventos',
                component: ListadoEventos,
                props: true,

            },
            {
                path: '/viewevento',
                name: 'viewevento',
                component: ViewEventoDetalle,
                props: true,

            },

            // PATHS CON AUTENTICACIÓN
            {
                path: '/envios',
                name: 'envios',
                component: ModoEnvio,
                props: true,
                meta: {
                    requiresAuth: true
                }

            },
            {
                path: '/pago',
                name: 'pago',
                component: MetodoPago,
                props: true,
                meta: {
                    requiresAuth: true
                }

            },

            {
                path: '/verificacionpagos',
                name: 'verificacionpagos',
                component: ResultadoPago,
                props: true,
                meta: {
                    requiresAuth: false
                }

            },

            {
                path: '/profile',
                name: 'profile',
                component: ViewCliente,
                props: true,
                meta: {
                    requiresAuth: true
                }

            },

            {
                path: '/pedidodetalles',
                name: 'pedidodetalles',
                component: ViewPedidoDetalle,
                props: true,
                meta: {
                    requiresAuth: true
                }

            },

            {
                path: '/compratickets',
                name: 'compratickets',
                component: CompraTickets,
                props: true,
                meta: {
                    requiresAuth: true
                }

            },

            {
                path: '/pagotickets',
                name: 'pagotickets',
                component: PagoTickets,
                props: true,
                meta: {
                    requiresAuth: true
                }

            },
            {
                path: '/verificacionpagoevento',
                name: 'verificacionpagoevento',
                component: ResultadoPagoEvento,
                props: true,
                meta: {
                    requiresAuth: false
                }

            },

            // FOOTER INFORMACIÓN
            {
                path: '/nosotros',
                name: 'nosotros',
                component: Nosotros,
                props: false,
        
            },
            {
                path: '/politicas',
                name: 'politicas',
                component: Politicas,
                props: false,
        
            },
            {
                path: '/terminos',
                name: 'terminos',
                component: Terminos,
                props: false,
        
            },
            {
                path: '/garantias',
                name: 'garantias',
                component: Garantias,
                props: false,
        
            },
            {
                path: '/contacto',
                name: 'contacto',
                component: Contacto,
                props: false,
        
            },
        
        ]
    },
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {

      if (store.getters.isLoggedIn) {
          if (to.name == 'home') {
              localStorage.setItem('loadData', 0);
              //this.$router.push('index');
          }
          next();
          return;
      }

      store.dispatch("logout");
      //next('/home'); // VERIFICAR SI ESTA CORRECTO EL PROCESO DE ENVIAR AL LOGIN Y NO AL HOME 22092024
      next('/login');
      console.log('debes iniciar session');

  } else {

      if (to.name == 'home') {
          localStorage.setItem('loadData', 0);
      }
      
      next();
  }
});

const originalPush       = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
