<template>
    <div>
      <main style="margin-top: 92px;">
            <div class="container path pt-3 mb-4">
                <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a class="text-decoration-none text-morado fw-medium" @click="goHome()" style="cursor: pointer;">
                                <i class="fas fa-home-alt text-morado"></i> Inicio</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="text-decoration-none text-morado fw-medium" @click="Regresar()" style="cursor: pointer;"> {{ $limitCharacters(objEvento.NomEvento,25) }}</a>
                        </li>
                        <li class="breadcrumb-item active fw-medium" aria-current="page">Seleccion de Boletos</li>
                    </ol>
                </nav>
            </div>
            <hr>

            <section>
                <div class="container mb-4">
                    <div class="row">

                        <div class="col-lg-8 mb-4">
                            <h2>Elige el tipo de boleto que deseas</h2>
                            <div class="d-flex justify-content-between align-items-end">
                                <span class="text-morado" v-if="parseInt(objEvento.MaxAccesoPorUsuario) == 1 && objEvento.Estatus == 'Publico'"><b>El evento solo permite un boleto por usuario</b></span>
                            </div>
                        </div>

                        <div class="col-12 col-md-7 col-lg-8">
                            <CLoader :pConfigLoad="ConfigLoad">
                                <template slot="BodyFormLoad">
                                    <div v-if="listAccesosDisponibles.length > 0 && objEvento.Estatus == 'Publico' " class="products-added-list">
                                        <div v-for="(item,idex) in listAccesosDisponibles" :key="idex" class="product-added">
                                            <div class="me-4">
                                                <input type="radio" :id="'dir_'+item.IdAcceso" :value="item.IdAcceso" @change="RessetPases(item)" v-model="IdAccesoSeleccionado" />
                                            </div>

                                            <figure class="img-thumbnail" style="border:none;">
                                                <i class="fad fa-ticket-alt fa-lg" style="font-size: 3em !important;"></i>
                                            </figure>
                                            
                                            <div class="details">
                                                <h3 class="name"> {{ item.NomAcceso }} </h3>
                                                <p class="size-color">{{ item.Descripcion }}</p>
                                                <p class="price">${{$formatNumber(item.Precio,"")}}</p>

                                                
                                            </div>

                                            <div v-if="parseInt(objEvento.MaxAccesoPorUsuario) > 1 && item.IdAcceso == IdAccesoSeleccionado" class="cantidad">
                                                <div class="d-flex align-items-center me-1">
                                                    <!-- SET MIN -->
                                                    <button @click="setMin(item)" type="button" class="btn qty-btn">
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                    
                                                    <!-- INPUT CANTIDAD -->
                                                    <input type="text" v-model="item.Cantidad" class="form-control input-qty" aria-label="Cantidad" readonly/>

                                                    <!-- SET PLUS -->
                                                    <button @click="setPlus(item)" type="button" class="btn qty-btn">
                                                        <i class="fas fa-plus"></i> 
                                                    </button>
                                                </div>
                                            </div>

                                            
                                        
                                        </div>
                                        
                                    </div>

                                    <div v-if="listAccesosDisponibles.length == 0 && ConfigLoad.ShowLoader == false" class="products-added-list">
                                        <br>
                                        <div class="text-center">
                                            <h3 class="text-morado">Lo sentimos</h3>
                                            <h6>No existen boletos para este evento.</h6>
                                        </div>
                                        <br>
                                    </div>

                                    <div v-if="objEvento.Estatus != 'Publico' && ConfigLoad.ShowLoader == false" class="products-added-list">
                                        <br>
                                        <div class="text-center">
                                            <h3 class="text-morado">Agradecemos tu Prefencia.</h3>
                                            <h6>Sentimos, informarte que los boletos para este evento ya se encuentran agotados.</h6>
                                        </div>
                                        <br>
                                    </div>
                                    
                                    <br>
                                    <br>
                                    <br>
                                </template>
                            </CLoader>
                            
                        </div>


                        <!-- RESUMEN DEL PEDIDO -->
                        <div class="col-12 col-md-5 col-lg-4">
                            <div class="summary">
                                <h5 class="text-center fs-4 mb-3">Resumen</h5>
                                
                                <div class="coupon mb-4">
                                    <div class="d-flex justify-content-between">
                                        <input type="text" v-model="KeyCupon" class="form-control" id="coupon" placeholder="Ingresa tu cupón de descuento" aria-label="Cupon" />
                                        <button type="button" @click="checkCupon()" class="btn btn-primary-2 ms-2">
                                            <span v-if="blockCupon" class="text-white spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <template v-else>Aplicar</template>
                                        </button>
                                    </div>
                                </div>
                                <div class="total-price-breakdown mb-3">
                                    <h5 class="mb-2">Detalle de compra</h5>

                                    <div class="section">
                                        <span class="title">Boletos:</span>
                                        <span>{{ $formatNumber(compraBoleto.Subtotal,'$') }}</span>
                                    </div>
                                    
                                    <div class="section">
                                        <span class="title">Descuentos:</span>
                                        <span>{{ $formatNumber(compraBoleto.Descuento,'$') }}</span>
                                    </div>

                                    <div class="total">
                                        <span>TOTAL</span>
                                        <span>{{ $formatNumber(compraBoleto.GranTotal,'$') }}</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <button @click="comprarTikets()" class="btn btn-primary w-100">Continuar compra</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import CLoader from '@/components/CLoader.vue';

export default {
    name: 'ViewTickets',
    props:[],
    components:{
        CLoader
    },

    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
            loadCupon: false,
            CheckUserPayments: true,

            listAccesosDisponibles: [],

            objEvento:{
                IdEvento: 0,
                NomEvento: "",
                Descripcion: "",
                Actividades: "",
                FechaEvento: "",
                IdCreador: 0,
                Estatus: "",
                Activo: "",
                Aforo: 0,
                SoloZin: 0,
                MaxAccesoPorUsuario: 0,
                LogoEvento: "",
                Imagen: "",
                ArchivoTerminosCondiciones: "",
                rutaImgs:''
            },

            blockCupon: false,
            KeyCupon: '',

            compraBoleto:{
                IdEvento: 0,
                IdAcceso: 0,
                NoTarjeta :'',
                MetodoPago: 'card',

                isAcepTerminos:false,
                UsoCupon: 0,
                IdCuponEvento: 0,

                Cantidad: 1,
                Subtotal: 0,
                Descuento: 0,
                GranTotal: 0,
            },

            IdAccesoSeleccionado: 0,
        };
    },

    mounted() {
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0,0);

        if(this.pEvento != undefined && this.pEvento != null) {
            this.objEvento =  this.pEvento;

        }else {
            if( sessionStorage.getItem('infoGeneral') != undefined && sessionStorage.getItem('infoGeneral') != null && sessionStorage.getItem('infoGeneral') != '' ) {
                this.objEvento = JSON.parse(sessionStorage.getItem('infoGeneral'));
            }
        }

        this.getAccesosDiponibles();
        this.verificaComprasUsuario();
    },

    methods: {
        async verificaComprasUsuario() {
            console.log('buscando compras previas...');

            if( this.CheckUserPayments ) {

                this.ConfigLoad.ShowLoader = true;

                let newRequest = {
                    IdEvento: this.objEvento.IdEvento,
                    TipoVerificacion: 2
                };
                
                await this.$http.post('checkcompraszin', newRequest)
                .then( (res) => {
                    
                    if(res.data.status && parseInt(res.data.ECode) === 4) {
                        console.log('pasoInicial'); //this.pasoSiguiente(1);
                        

                    }else if (res.data.status && parseInt(res.data.ECode) === 3) {
                        
                        this.objVerificacionCobro = res.data.data;
                        this.$toast.info('Ya has comprado un pase anteriormente');
                        console.log('Ya ha tenido compras el usuario'); //this.pasoSiguiente(3);

                    }else if(!res.data.status && res.data.ECode === 1) {
                        // si el pedido no se ha completado o si se completo fuera de tiempo.
                        this.$toast.info(res.data.message);

                        if(res.data.data.method == 'card') {
                            if(res.data.data.urlPasarela && res.data.data.urlPasarela != undefined){
                                //this.urlPasarela = res.data.data.urlPasarela;
                                //this.IdVentaEvento = res.data.data.IdVentaEvento;
                                console.log('Envia a la pasarela de pagos'); //this.pasoSiguiente(4);

                            }else {
                                this.$toast.error('Ah ocurrido un error, no se ha podido continuar con el proceso de pago.');
                                console.log('Error de compra, retorno al inicio'); //this.pasoSiguiente(1);
                            }

                        }else if(res.data.data.method == 'transfer') {
                            //this.objDatosPago   = res.data.data;
                            //this.IdVentaEvento  = res.data.data.IdVentaEvento;
                            console.log('tranferencia, pasa a final compra en pendiente'); // this.pasoSiguiente(7,'v');

                        }else {
                            this.$toast.error('Ah ocurrido un error, no se ha podido continuar con el proceso de pago.');
                            console.log('Error de compra, retorno al inicio'); //this.pasoSiguiente(1);
                        }
                        
                    }else if(!res.data.status && res.data.ECode === 2) {
                        this.$toast.error(res.data.message);
                    }
                    
                }).catch(err => {
                    //this.$toast.error('Ah ocurrido un error, no se ha podido verificar el cobro');

                }).finally(() => {
                    this.ConfigLoad.ShowLoader = false;
                });

            }else {
               
                this.ConfigLoad.ShowLoader = false;
            }
            
        },

        checkCupon() {

            if(this.KeyCupon !== '') {

                let newRequest = {
                    Key: this.KeyCupon,
                    IdEvento: this.objEvento.IdEvento,
                    IdAcceso: this.IdAccesoSeleccionado
                }
                this.compraBoleto.Descuento     = 0;
                this.compraBoleto.UsoCupon      = 0;
                this.compraBoleto.IdCuponEvento = 0;
                this.blockCupon = true;

                this.$http.post('checkcuponevento', newRequest).then((res) => {

                    if (res.data.status && parseInt(res.data.ECode) === 3) {
                        const dataCupon                 = res.data.data;

                        this.compraBoleto.UsoCupon          = 1;
                        this.compraBoleto.IdCuponEvento     = dataCupon.IdCuponEvento;
                        this.compraBoleto.Subtotal          = dataCupon.Subtotal;
                        this.compraBoleto.Descuento         = parseFloat(dataCupon.ValorDescuento);
                        this.compraBoleto.GranTotalTotal    = parseFloat(dataCupon.CostoTotal);

                        this.$toast.success('Cupón aplicado con exito.','Felicidades!','');

                    }else if(!res.data.status && res.data.ECode === 1) {
                        this.blockCupon = false;
                        this.$toast.warning(res.data.message,'Alerta!','');

                    }else if(!res.data.status && res.data.ECode === 2) {
                        this.blockCupon = false;
                        this.$toast.warning('Cupón Vencido','Alerta!','');
                    }

                    //this.Operaciones();
                }).catch(err => {
                    console.log(err);
                    this.$toast.error(err.response.data.message,'Error!','');
                    this.compraBoleto.UsoCupon      = 0;
                    this.compraBoleto.IdCuponEvento = 0;
                    this.blockCupon = false;
                            
                });
            }else{
                this.$toast.warning('Escribe un codigo','Alerta!','');
                this.compraBoleto.UsoCupon      = 0;
                this.compraBoleto.IdCuponEvento = 0;
            }
        },

        getAccesosDiponibles() {
            this.ConfigLoad.ShowLoader = true;
            this.$http.get('accesos', {
                params:{
                    Entrada: 8,
                    Pag: 1,
                    IdEvento: this.objEvento.IdEvento,
                    Or:'sh'
                    
                }

            }).then( (res) => {
                let listaTemp = res.data.data.data;
                this.listAccesosDisponibles 			= listaTemp.map( item => ({
                    
                    IdAcceso:  item.IdAcceso,
                    IdCreador : item.IdCreador,
                    IdEvento: item.IdEvento,
                    NomAcceso: item.NomAcceso,
                    Descripcion: item.Descripcion,
                    NoExistencias: item.NoExistencias,
                    Precio: item.Precio,
                    Estatus: item.Estatus,
		            Utilizados: item.Utilizados,
                    Cantidad: 1
                }));
                
            }).catch( err => {
                if(err.response.data.errors){
                    this.errorvalidacion=err.response.data.errors;
                }else{
                    this.$toast.error(err.response.data.message);
                }

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
            })
        },

        setMin(item){
            let Precio          = parseFloat(item.Precio);
            let Cantidad        = (parseInt(item.Cantidad) - 1);
        
            if(Cantidad<=0){
               Cantidad = 1;

            }else{

                item.Cantidad = Cantidad;
                this.compraBoleto.Subtotal = parseFloat(Cantidad * Precio);
                this.compraBoleto.GranTotal = this.compraBoleto.Subtotal;
            }
        },

        setPlus(item){
            let disponibles     = (parseInt(item.NoExistencias) - parseInt(item.Utilizados))
            let maxporcliente   = parseInt(this.objEvento.MaxAccesoPorUsuario);
            let Precio          = parseFloat(item.Precio);
            let Cantidad        = (parseInt(item.Cantidad) + 1);
            
            if(Cantidad > maxporcliente) {
                console.log('stop1');
                return false;

            }else {

                if(Cantidad > disponibles) {
                    console.log('stop2');
                    return false;

                }else{
                    console.log('sumando'+Cantidad);
                    item.Cantidad = Cantidad;
                    this.compraBoleto.Subtotal  = parseFloat(Cantidad * Precio);
                    this.compraBoleto.GranTotal = this.compraBoleto.Subtotal;
                }
            } 
        },

        RessetPases(item){
            this.listAccesosDisponibles.forEach(element => {
                element.Cantidad = 1;
            });

            let Precio          = parseFloat(item.Precio);
            let Cantidad        = parseInt(item.Cantidad);

            this.compraBoleto.Subtotal  = (Cantidad * Precio);
            this.compraBoleto.GranTotal = this.compraBoleto.Subtotal;
        },

        Regresar() {
            this.$router.push({name: 'eventos', params:{}});
        },

        comprarTikets() {
            let acceso = this.listAccesosDisponibles.filter( (item) => item.IdAcceso == this.IdAccesoSeleccionado )

            if( acceso.length > 0) {
                let ventaEvento = {
                    Evento: this.objEvento,
                    IdAcceso: this.IdAccesoSeleccionado,
                    Acceso: acceso,
                    IdCuponEvento: this.compraBoleto.IdCuponEvento,
                    Subtotal: this.compraBoleto.Subtotal,
                    Descuento: this.compraBoleto.Descuento,
                    GranTotal: this.compraBoleto.GranTotal,
                }
                console.log(ventaEvento);
                sessionStorage.setItem('cevento',JSON.stringify(ventaEvento));
                this.$router.push({name: 'pagotickets', params:{}});
            }else {
                this.$toast.info('Necesita seleccionar un boleto para continuar','Alerta!','');
            }
            
        },
    },
};
</script>